<template>
  <div class="service">
    <div class="main">
      <div class="container">
        <div class="main-left">
          <h3>专业提供定制化方案<br>致力于互联网货运能力提升</h3>
          <ul>
<!--            <li><img src="@/assets/img/222.png" alt="">金融服务</li>-->
            <li><img src="@/assets/img/222.png" alt="">保险服务</li>
            <li><img src="@/assets/img/222.png" alt="">油气服务</li>
            <li><img src="@/assets/img/222.png" alt="">共享卡车</li>
            <li><img src="@/assets/img/222.png" alt="">车后市场</li>
            <li><img src="@/assets/img/222.png" alt="">税筹服务</li>
          </ul>
        </div>
        <div class="main-right">
          <h2>咨询顾问专线：<span style="color: #2acd8e">0951-2052182</span></h2>
          <p>请填写以下信息，我们会在24小时内与您联系<br>为您提供定制化的个性方案</p>
          <a-form>
            <a-form-item>
              <a-input size="large" placeholder="您公司的名称" v-model:value="listQuery.companyName" />
            </a-form-item>
            <a-form-item>
              <a-input size="large" placeholder="您的名称" v-model:value="listQuery.name" />
            </a-form-item>
            <a-form-item>
              <a-input size="large" placeholder="您的电话" v-model:value="listQuery.phone" />
            </a-form-item>

            <a-form-item class="btn">
              <a-button type="primary" size="large" block @click="handleSave">立即提交</a-button>
            </a-form-item>
          </a-form>
        </div>

      </div>
    </div>
    <div class="container">
      <section class="section">
        <h3>服务体系</h3>
        <h5>全方位、多层次涵盖六大类目</h5>
        <ul class="service-data">
          <li v-for="service in list">
            <router-link
              :to="{ path: 'Article', query: { id: service.id, title: service.title, content: service.content } }">
              <div class="top">
                <img :src="service.picture" alt="">
              </div>
              <div class="bottom" v-html="service.summary"></div>
            </router-link>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>


<script>

import { SmileOutlined, FrownOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { list, save } from "../api/service";
import { h } from "vue";


export default {
  name: "Service"
  , data() {
    return {
      list: null,
      listQuery: {
        companyName: null
        , name: null
        , phone: null
      }
    }
  }
  , mounted() {
    const columnId = this.$route.query.columnId;
    // console.log("service page:", columnId)
    const queryParam = { id: columnId }
    list(queryParam).then(response => {
      // console.log("service page list", response);
      this.list = response.data.list;
    })
  }
  , methods: {
    handleSave() {
      save(this.listQuery).then(response => {
        console.log(response);
        if (response.errCode == 0) {
          notification.open({
            message: '信息提交成功'
            , description: '信息提交成功，等待工作人员联系。'
            , duration: 2
            , icon: () => h(SmileOutlined, { style: 'color: #108ee9' })
          })
          // 清空表单数据
          this.listQuery.companyName = "";
          this.listQuery.name = "";
          this.listQuery.phone = "";

        } else {
          notification.open({
            message: '信息提交失败'
            , description: response.message
            , duration: 2
            , icon: () => h(FrownOutlined, { style: 'color:#F73316' })
          })
        }
      })
    },
  }

}
</script>






<style scoped lang="scss">
.service {
  padding-top: 66px;
}

.main {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 30px 0;

  & .main-left {
    font-size: 44px;
    width: 100%;
    color: white;
    line-height: 70px;

    @media (min-width: 414px) {
      float: left;
      width: 60%;
    }


    &>h3 {
      margin-bottom: 50px;
      font-size: 44px;
      color: inherit;
      letter-spacing: 3px;
    }

    &>ul {
      display: flex;
      flex-wrap: wrap;

      &>li {
        flex: 0 0 calc(33.33% - 10px);
        font-size: 24px;
        line-height: 28px;
        padding: 10px 0;

        &>img {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
      }
    }
  }

  & .main-right {
    width: 100%;
    background: rgba(179, 179, 179, 0.36);
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    align-self: center;

    @media (min-width: 414px) {
      width: 40%;
      float: left;
    }

    & h2 {
      color: white;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    & p {
      color: white;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    & .btn {
      margin-bottom: 0;
    }
  }
}

.main {
  background-image: url("../assets/img/bg/service.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100% - 80px);
}

ul.service-data {
  display: block;

  @media (min-width: 414px) {
    display: flex;
  }

  flex-wrap: wrap;

  &>li {
    flex: 0 0 calc(33.333333% - 10px);
    padding: 15px;
    //border: 1px solid #DBE0E5;
    border-right: none;

    &:last-child {
      //border-right: 1px solid #DBE0E5;
    }

    & .top {
      &>img {
        display: block;
        width: 100%;
      }
    }

    & .bottom {
      font-size: 1.6rem;
      padding: 10px 18px;
      text-align: justify;
    }
  }
}

.section {
  margin-bottom: 40px;

  &>h3 {
    text-align: center;
    font-size: 40px;
    color: #333;
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
  }

  &>h5 {
    font-size: 20px;
    text-align: center;
    line-height: 22px;
    margin-bottom: 40px;
  }
}
</style>
