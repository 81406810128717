<template>
  <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 8 }" autocomplete="off"
           class="foem"  ref="insertPriceList" @finish="submit()">
    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item label="公司名称:" name="companyName" :rules="[{ required: true, message: '请输入公司名称' }]">
          <a-input v-model:value="formState.companyName"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="公司简称:" name="shortName" :rules="[{ required: true, message: '请输入公司简称' }]">
          <a-input v-model:value="formState.shortName"/>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item name="companyType" label="公司类型:" :rules="[{ required: true, message: '请选择公司类型' }]">
          <a-select v-model:value="formState.companyType" :options="areas"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item label="营业执照号码:" name="tradeNum" :rules="[{ required: true, message: '请输入营业执照号码' }]">
          <a-input v-model:value="formState.tradeNum"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="法人姓名:" name="legalPerson" :rules="[{ required: true, message: '请输入法人姓名' }]">
          <a-input v-model:value="formState.legalPerson"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="法人证件号:" name="personIdcard" :rules="[{ required: true, message: '请输入法人证件号' }]">
          <a-input v-model:value="formState.personIdcard"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item label="经办人:" name="contactPerson" :rules="[{ required: true, message: '请输入经办人' }]">
          <a-input v-model:value="formState.contactPerson"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="经办人证件号:" name="contactIdcard"
                     :rules="[{ required: true, message: '请输入经办人证件号' }]">
          <a-input v-model:value="formState.contactIdcard"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="经办人电话:" name="contactPhone" :rules="[{ required: true, message: '请输入经办人电话' }]">
          <a-input v-model:value="formState.contactPhone"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item name="validTime" label="证件有效期" :rules="[{ required: true, message: '请选择证件有效期' }]">
          <a-date-picker v-model:value="formState.validTime" class="datapick" value-format="YYYY-MM-DD"/>
        </a-form-item>
      </a-col>
      <a-col :span="16">
        <a-form-item name="summary" label="公司描述:" :rules="[{ required: true, message: '请输入公司描述' }]">
          <a-textarea v-model:value="formState.summary"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item name="logo" label="公司LOGO:" :rules="[{ required: true, message: '请上传公司LOGO' }]">
          <a-upload  name="picture" list-type="picture-card" class="avatar-uploader"
                   :show-upload-list="false" action="https://admin.api.wzcwlw.com/archives/region/uploadFile"
                    :before-upload="beforeUpload" @change="handleChange" >
            <img v-if="formState.logo" style="width:100px" :src="formState.logo" alt="avatar"/>
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">+</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item name="tradeImg" label="营业执照图片:" :rules="[{ required: true, message: '请上传营业执照图片' }]">
          <a-upload  name="picture" list-type="picture-card" class="avatar-uploader"
                    :show-upload-list="false" action="https://admin.api.wzcwlw.com/archives/region/uploadFile"
                    :before-upload="beforeUpload" @change="handleChange1" >
            <img v-if="formState.tradeImg" style="width:100px" :src="formState.tradeImg" alt="avatar"/>
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">+</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item name="personImg" label="法人身份证:" :rules="[{ required: true, message: '请上传法人身份证' }]">
          <a-upload  name="picture" list-type="picture-card" class="avatar-uploader"
                    :show-upload-list="false" action="https://admin.api.wzcwlw.com/archives/region/uploadFile"
                    :before-upload="beforeUpload" @change="handleChange2">
            <img v-if="formState.personImg" style="width:100px" :src="formState.personImg" alt="avatar"/>
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">+</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="8">
        <a-form-item name="provinceId" label="所在省:" :rules="[{ required: true, message: '请选择所在省' }]">
          <a-select v-model:value="formState.provinceId" :options="province" @change="handleprovince"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item name="cityId" label="所在市/区:" :rules="[{ required: true, message: '请选择所在市/区' }]">
          <a-select v-model:value="formState.cityId" :options="city" @change="handlecity"/>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item name="areaId" label="所在县:" :rules="[{ required: true, message: '请选择所在县' }]">
          <a-select v-model:value="formState.areaId" :options="area"/>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="24">
        <a-form-item name="address" label="详细地址:" :rules="[{ required: true, message: '请输入详细地址' }]">
          <a-input id="address" v-model:value="formState.address"/>
          <div id="searchResultPanel" style="border:1px solid #C0C0C0;width: 150px;height: auto;display: none;"></div>
        </a-form-item>
      </a-col>
    </a-row>
    <div id="container" style="width: 80%;height: 300px;margin: 0 auto;"></div>


    <a-form-item :wrapper-col="{ offset: 8, span: 8 }" style="margin-top:36px;">
      <a-button type="primary" style="margin-left:20%;" html-type="submit">注册</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {provinces, citys, areaitem,addcompany} from '@/api/register';

export default ({
  name: "register",

  data() {
    return {
      formState: {
        companyName: '',
        shortName: '',
        companyType: '',
        trustFlag: '2',
        provinceId: '',
        cityId: '',
        areaId: '',
        personImg: '',
        tradeImg: '',
        logo: '',
        summary: '',
        validTime: '',
        contactPhone: '',
        contactIdcard: '',
        contactPerson: '',
        personIdcard: '',
        legalPerson: '',
        tradeNum: '',
        address: "",
        lng: '',
        lat: '',
        location: ''
      },
      loading: false,
      areas: [
        {label: '集团', value: '1'},
        {label: '区域', value: '2'},
        {label: '公司', value: '3'},
        {label: '子公司', value: '4'},
        {label: '分公司', value: '5'},
      ],
      province: [],
      city: [],
      area: [],
      mapZoom: 15,
      userlocation: {lng: "", lat: ""},
    }
  },
  mounted() {
    let that = this;
    const map = new BMapGL.Map("container");
    map.enableScrollWheelZoom(true);
    new BMapGL.LocalCity().get(e => {
      map.centerAndZoom(e.name, 15);
    });

    var ac = new BMapGL.Autocomplete(
        {
          "input": "address",
          "location": map
        })

    var myValue;
    ac.addEventListener("onconfirm", function (e) {    //鼠标点击下拉列表后的事件
      var _value = e.item.value;
      myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
      that.formState.address = myValue;
      setPlace();
    });

    function setPlace() {
      map.clearOverlays();    //清除地图上所有覆盖物
      function myFun() {
        var pp = local.getResults().getPoi(0).point;
        that.formState.location = pp.lng + ',' + pp.lat  //返回选中坐标点
        map.centerAndZoom(pp, 18);
        map.addOverlay(new BMapGL.Marker(pp));    //添加标注
      }

      var local = new BMapGL.LocalSearch(map, { //智能搜索
        onSearchComplete: myFun
      });
      local.search(myValue);
    }

  },
  created() {

    provinces({pid: 0}).then(res => {
      for (let key in res.data) {
        this.province.push({'value': res.data[key].id, 'label': res.data[key].name})
      }
    })


  },
  methods: {
    submit() {
      
      addcompany(this.formState).then(res=>{
         if(res.errCode==0){
          this.$message.success("注册成功！")
          this.formState={
        companyName: '',
        shortName: '',
        companyType: '',
        trustFlag: '2',
        provinceId: '',
        cityId: '',
        areaId: '',
        personImg: '',
        tradeImg: '',
        logo: '',
        summary: '',
        validTime: '',
        contactPhone: '',
        contactIdcard: '',
        contactPerson: '',
        personIdcard: '',
        legalPerson: '',
        tradeNum: '',
        address: "",
        lng: '',
        lat: '',
        location: ''
          }
          this.$router.push('/')
         }else{
        this.$message.error(res.message);
         }
      })
   

    },
    handleprovince() {
      this.city = []
      this.area = []
      this.formState.cityId = ''
      this.formState.areaId = ''
      citys({pid: this.formState.provinceId}).then(res => {
        for (let key in res.data) {
          this.city.push({'value': res.data[key].id, 'label': res.data[key].name})
        }
      })
    },
    handlecity() {
      this.formState.areaId = ''
      areaitem({pid: this.formState.cityId}).then(res => {
        for (let key in res.data) {
          this.area.push({'value': res.data[key].id, 'label': res.data[key].name})
        }
      })
    },

    handleChange(info) {
      if (info.file.status === 'done') {
        const {errCode, data} = info.file.response
        if (errCode === 0) {
          this.formState.logo = data.url
        }
      }
    },

    handleChange1(info) {
      if (info.file.status === 'done') {
        const {errCode, data} = info.file.response
        if (errCode === 0) {
          this.formState.tradeImg = data.url
        }
      }
    },

    handleChange2(info) {
      if (info.file.status === 'done') {
        const {errCode, data} = info.file.response
        if (errCode === 0) {
          this.formState.personImg = data.url
        }
      }
    },

    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('文件不能大于2MB!');
      }
      return isJpgOrPng && isLt2M;
    }
  }
});
</script>
<style>
.foem {
  margin-top: 120px;
  margin-right: 20px;
}
#platform{
  z-index: 0!important;
}

.bm-view {
  width: 100%;
  height: 500px;
}

.ant-picker {
  width: 100%;
}

.ant-col-8 {
  max-width: 80%;
}

.ant-form-item-label {
  max-width: 160px !important;
}
</style>